.navbar_wrapper {
  font-family: sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
  position: relative;
  background: #040923;
}
.navbar_wrapper a {
  text-decoration: none;
}

.nav_elements a {
  margin-left: 20px;
  transition: 0.25s ease;
}
.nav_elements a:hover {
  filter: brightness(0.5);
}

.sidebar_wrapper {
  position: fixed;
  display: flex;
  height: 100vh;
  top: 0;
  right: 0;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  transition: 0.5s;
  width: 0px;
  z-index: 999;
  background: rgb(4, 9, 35);
}

.sidebar_wrapper button {
  font-size: 25px;
  width: fit-content;
  margin: 20px;
  padding: 0;
  margin-left: auto;
  border: 0;
  height: fit-content;
  line-height: 1;
  cursor: pointer;
  background: none;
}

.sidebar_wrapper .links_wrapper a {
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.6);
}
.sidebar_wrapper a:hover {
  filter: brightness(0.8);
}

@media screen and (max-width: 767px) {
  .left {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .left_logo {
    margin-left: 10px;
  }
  .left_nav {
    left: 0;
    right: inherit;
  }
  .btn_left {
    margin-left: 20px !important;
  }
  .nav_elements {
    display: none;
  }
  .mob_nav {
    display: block;
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
}
