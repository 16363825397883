@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  height: 100%;
  overflow: auto;
}

body {
  height: 100%;
}
svg {
  max-width: 100%;
  height: auto;
}

.main-app > div {
  max-width: 1920px;
  width: 100%;
}

body ::-webkit-scrollbar-track {
  background-color: transparent;
}
body ::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
}

.dark {
  --color-bg-primary: #050d32;
  --color-bg-secondary: rgba(255, 255, 255, 0.05);
  --color-bg-thirdry: #23284e;
  --color-bg-fourth: #11193c;
  --color-bg-fifth: #12193c;
  --color-bg-overlay: #101b30;
  --color-bg-table-row: #1b1f3e;
  --color-bg-success: #24a148;
  --color-bg-danger: #fa4d56;
  --color-bg-manta-gray: #acacb4;
  --color-bg-nav: #040923;
  --color-bg-button-primary: #0d7bc5;
  --color-bg-button-secondary: #2b49ea;
  --color-bg-button-thirdry: #00afa5;
  --color-bg-button-third-80: rgba(0, 175, 165, 0.8);
  --color-bg-button-fourth: #0e50fc;
  --color-text-primary: #ffffff;
  --color-text-secondary: #a4a3a7;
  --color-text-thirdry: #ffffff;
  --color-text-accent: #ffffff;
  --color-text-warning: #e96e79;
  --color-manta-blue: #239cff;
  --color-fill-secondary: #ffffff;
  --color-border-primary: #6eaed7;
  --color-border-secondary: #595f6d;
  --gradient-bg-primary: linear-gradient(to bottom right, #00182a, #000011);
  --gradient-button-primary: linear-gradient(to right, #26c8bf, #1fa2f9);
  --gradient-button-secondary: linear-gradient(
    90.17deg,
    #2b49ea -7.65%,
    #00afa5 103.69%
  );
  --gradient-button-third: linear-gradient(
    88.95deg,
    #29ccb9 0%,
    #0091ff 49%,
    #ff66b7 100%
  );
  --gradient-zk-kyc:linear-gradient(90deg, rgba(255, 253, 195, 0.31) 0%, rgba(190, 255, 180, 0.48) 100%);
  --gradient-zk-credential: linear-gradient(90deg, rgba(140, 214, 255, 0.60) 0%, rgba(86, 58, 255, 0.31) 100%);
  --gradient-zk-portrait: linear-gradient(90deg, rgba(58, 253, 77, 0.31) 0%, rgba(165, 94, 255, 0.48) 100%);;
  --gradient-about-light:linear-gradient(71deg, #BEFFE8 0%, #F8D0FF 100%);
  --gradient-about-dark:linear-gradient(137deg, rgba(45, 255, 237, 0.10) 0%, rgba(58, 167, 255, 0.10) 100%);
  --swiper-navigation-size: 16px;
  --swiper-navigation-color: white;
  --swiper-pagination-color: #29ccb9;
  --swiper-pagination-bullet-inactive-color: rgba(255, 255, 255, 1);
  --giantsquid-navbar-bg: linear-gradient(90deg, #eb559f 0%, #f3ab82 100%);
}

.light {
  --color-bg-primary: #f2f2f4;
  --color-bg-secondary: #ffffff;
  --color-bg-thirdry: #f2f2f4;
  --color-bg-fourth: #61abb9;
  --color-bg-fifth: #12193c;
  --color-bg-overlay: #e3e4e8;
  --color-bg-table-row: #f9fbfd;
  --color-bg-success: #24a148;
  --color-bg-danger: #fa4d56;
  --color-bg-manta-gray: #7e7e7e;
  --color-bg-nav: #040923;
  --color-bg-button-primary: #0d7bc5;
  --color-bg-button-secondary: #2b49ea;
  --color-bg-button-thirdry: #00afa5;
  --color-bg-button-third-80: rgba(0, 175, 165, 0.8);
  --color-bg-button-fourth: #0e50fc;
  --color-text-primary: #104ea1;
  --color-text-secondary: #212f41;
  --color-text-thirdry: #070707;
  --color-text-accent: #33385f;
  --color-text-warning: #ef9f9f;
  --color-manta-blue: #239cff;
  --color-fill-secondary: #889ebc;
  --color-border-primary: #6eaed7;
  --color-border-secondary: #595f6d;
  --gradient-bg-primary: linear-gradient(to bottom right, #b0d2eb, white);
  --gradient-button-primary: linear-gradient(to bottom right, #26c8bf, #1fa2f9);
  --gradient-button-secondary: linear-gradient(
    90.17deg,
    #2b49ea -7.65%,
    #00afa5 103.69%
  );
  --gradient-button-third: linear-gradient(
    88.95deg,
    #29ccb9 0%,
    #0091ff 49%,
    #ff66b7 100%
  );
  --gradient-zk-kyc:linear-gradient(90deg, rgba(255, 253, 195, 0.31) 0%, rgba(190, 255, 180, 0.48) 100%);
  --gradient-zk-credential: linear-gradient(90deg, rgba(140, 214, 255, 0.60) 0%, rgba(86, 58, 255, 0.31) 100%);
  --gradient-zk-portrait: linear-gradient(90deg, rgba(58, 253, 77, 0.31) 0%, rgba(165, 94, 255, 0.48) 100%);;
  --swiper-navigation-size: 16px;
  --swiper-navigation-color: white;
  --swiper-pagination-color: #29ccb9;
  --swiper-pagination-bullet-inactive-color: rgba(255, 255, 255, 1);
  --giantsquid-navbar-bg: linear-gradient(90deg, #eb559f 0%, #f3ab82 100%);
}

.custom-colors {
  --color-fill-primary: #4caaac;
  --color-fill-gray: #889ebc;
  --color-text-gray: #7e7e7e;
}

.custom-width {
  --min-width-layout: 450px;
  --min-width-table: 1024px;
  --min-width-md: 700px;
  --min-width-lg: 1200px;
}

.gradient-text {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: var(--gradient-button-primary);
  color: transparent;
}

.gradient-button {
  background-image: var(--gradient-button-secondary);
}

/* .btn-animation {
  @apply transition duration-100 ease-in-out transform hover:-translate-y-1;
} */
