.el-select {
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  color: white !important;
  .el-input {
    .el-input__inner {
      background-color: rgba(255, 255, 255, 0) !important;
      border: 0;
      font-size: 22px !important;
      padding-left: 14px;
      padding-right: 0px;
      color: white !important;
      text-align: center;
    }
    .el-input__icon {
      color: $blue-primary-color !important;
      font-size: 11px !important;
      right: 10px;
      background-color: white;
      width: 20px;
      border-radius: 50%;
      padding: 0;
      height: 20px;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white !important;
      opacity: 1;
      font-size: 18px;
      margin-bottom: 5rem !important;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: white !important;
      font-size: 18px;
      padding-bottom: 0.5rem;
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: white !important;
      font-size: 18px;
      padding-bottom: 0.5rem;
    }
  }
  .el-select-dropdown {
    color: $blue-primary-color !important;
    border-radius: 2px 2px 10px 10px;
    left: 0 !important;
    .el-scrollbar {
      margin: 0 -1px;
    }
    &__list {
      padding: 0;
    }
  }
  .el-select-dropdown__item {
    height: auto !important;
    &.selected {
      border-radius: 12px;
      background-color: $blue-primary-color !important;
    }
    &.hover {
      border-radius: 12px;
    }
  }
}

.form-select-label {
  left: 67px;
}

// loading spinner
.el-loading-spinner {
  display: flex !important;
  align-items: center;
  justify-content: center;
  .el-loading-text {
    padding: 0 20px;
  }
}

// notifications
.el-notification {
  border-radius: 10px !important;
  padding: 16px !important;
  background-color: var(--color-bg-fifth) !important;
  width: 360px !important;
  &__icon {
    top: 12px !important;
    font-size: 30px !important;
  }
  &__content {
    margin-top: 5px !important;
  }
  &__title {
    color: white !important;
    display: none;
  }
  &__closeBtn {
    top: 10px !important;
    right: 10px !important;
    color: white !important;
  }
}

.el-notification__icon {
  margin-left: 8px;
}
.el-notification__closeBtn {
  margin-top: 8px;
  margin-right: 8px;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.spinner-border {
    vertical-align: -0.125em em;
    border: 0.25em solid;
    border-right-color: transparent;
}

.zkAddressTooltipWrapper{
    max-width: 150px;
    .el-tooltip__popper{
        background: unset !important;
        color: unset !important;
    }
}
.zkAddressTooltip{
    position: absolute;
    top: 20px;
    left: -10px;
    color: rgba(255, 255, 255, 0.6) !important;
    background: rgba(0, 6, 32, 0.8) !important;
    border-radius: 4px !important;
    border: unset !important;
    font-size: 10px !important;
    padding: 2px 5px !important;
    line-height: 16px !important;
    width: unset !important;
}
