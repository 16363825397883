.sbt-page {
  .el-popover {
    border-radius: 0.5rem;
    background: var(--color-bg-secondary);
    border-color: var(--color-bg-secondary);
  }
  .el-popover .popper__arrow {
    display: none;
  }
  .disconnect-pop {
    background: rgba(0, 0, 0, 0.8);
    border-color: rgba(0, 0, 0, 0.8);
  }
  .img-bg {
    background: url(../../resources/images/img-bg.png) no-repeat center
      center/100% 100%;
  }
  .upload-img-cover {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    width: 71.3rem;
    height: 12rem;
    background: linear-gradient(180deg, #050d32 0%, rgba(18, 25, 60, 0) 100%);
  }

  .token-master {
    background: linear-gradient(
      90deg,
      rgba(255, 126, 68, 0.3) 0%,
      rgba(41, 248, 235, 0.3) 100%
    );
    border: 1px solid rgba(8, 175, 203, 0.5);
  }
  .token-master-active {
    background: linear-gradient(
      90deg,
      rgba(255, 126, 68, 0.8) 0%,
      rgba(41, 248, 235, 0.5) 100%
    );
    border: 1px solid #08afcb;
  }
  .token-supreme {
    background: linear-gradient(
      90deg,
      rgba(216, 164, 0, 0.3) 0%,
      rgba(254, 146, 97, 0.3) 100%
    );
    border: 1px solid rgba(255, 176, 138, 0.5);
  }
  .token-supreme-active {
    background: linear-gradient(
      90deg,
      rgba(216, 164, 0, 0.8) 0%,
      rgba(254, 146, 97, 0.8) 100%
    );
    border: 1px solid #ffb08a;
  }
  .token-normal {
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  .token-normal-active {
    background: rgba(41, 204, 185, 0.5);
    border: 1px solid #29ccb9;
  }

  .text-gridient {
    background: linear-gradient(90.17deg, #8c9eff -7.65%, #00afa5 103.69%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .text-mutilple-overflow {
    display: -webkit-box;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: normal;
    hyphens: auto;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    max-height: 80px;
  }
  .sbt-faq-content {
    .el-collapse {
      width: 100%;
      border: none;

      .el-collapse-item__header__arrow {
        display: none;
      }
      .el-collapse-item {
        margin-top: 8px;
        .el-collapse-item__header {
          border: none;
          border-radius: 8px;
          background: #050d32;
          color: rgba(255, 255, 255, 0.8);
        }
        .el-collapse-item__wrap,
        .el-collapse-item__content {
          border: none;
          background: #050d32;
          color: rgba(255, 255, 255, 0.8);
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
      .el-collapse-item.is-active {
        .el-collapse-item__header {
          border-bottom-left-radius: unset;
          border-bottom-right-radius: unset;
        }
      }
    }
  }
}
